label {
    display: block;
    margin-top: 10px;
}

.card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.auth-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;
}

.wrapper {
    background-color: rgb(231, 235, 240);
    min-height: 100vh;
}

.alert {
    margin-top: 20px;
    text-align: center;
}

.pagination{
}

.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {

}

.MuiDataGrid-cell {


    white-space: initial !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: flex-start;
}

